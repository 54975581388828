// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');
require('webpack-jquery-ui');
require('webpack-jquery-ui/css');
require('jquery-ui-timepicker-addon/dist/jquery-ui-timepicker-addon.css');
import 'bootstrap';
import 'bootstrap-colorpicker';
import 'popper.js';
import 'octicons';
import '@fullcalendar/core';
import '@fullcalendar/daygrid';
import '../components/geka-kalender.js';

document.addEventListener('turbolinks:before-render', () => {
    // Components.unloadAll();
});

document.addEventListener('turbolinks:load', () => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    $('.color-picker').colorpicker();
});

class GK {
    util = {
        // TODO: i'm sure there is a formatting function for this;
        leading0: (n) => {
            return n < 10 ? '0' + n : n;
        }
    }
}
window.GK = new GK;

// default settings for all datepicker fields
// Attention: Several local configs used as well!! (events!)
window.datepickerSettings = {
    dateFormat: 'dd.mm.yy',
    timeFormat: 'HH:mm',
    minuteGrid: 30,
    stepMinute: 30,
    controlType: "slider",
    currentText: "Jetzt",
    closeText: "Ok",
    timeText: "Uhrzeit",
    hourText: "Stunde",
    minuteText: "Minute",
    firstDay: 1 // Start with Monday
};


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
